import React, {FC, useContext} from 'react';
import {Link} from 'react-router-dom';
import {AssetsBasePathContext} from '../components/assetBasePathProvider';

import "../styles/tiles.scss";

interface LocationTileProps {
  image: string;
  title: string;
  highlights: string[]; // Now expecting an array of strings
  key: string;
  country: string;
  city: string;
  thumbnail: string;
}

const LocationTile: FC<LocationTileProps> = ({image, title, highlights, country, city, thumbnail}) => {
  const assetBasePath = useContext(AssetsBasePathContext);
  const url = `/location/${country.toLowerCase()}/${city.toLowerCase()}`;

  return (
    <Link to={url} className='location-tile'>
      {thumbnail ? 
      <img
          className="thumbnail"
          src={`${assetBasePath}/assets/${city.toLowerCase()}/${thumbnail}`}
          alt={title || "Heading"}
      />
      : <img src={image} alt={title} className='icon' />
}
      <h3>{title}</h3>
      <ul>
        {highlights.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </Link>
  );
};

export default LocationTile;
