import React, {useEffect, useState, useContext} from 'react';
import {useParams} from 'react-router-dom';
import log from '../client-requests/logger';
import {AssetsBasePathContext} from '../components/assetBasePathProvider';
import parse from "html-react-parser";

import '../styles/location.scss';

import locationsData from '../locations/all';

interface Location {
  id: string;
  title: string;
  city: string;
  country: string;
  summary: string;
  description: string;
  icon: string;
  highlights: string[];
  // TODO: Fix types once data structure nailed down
  heading_image?: string;
  images?: any;
  where_to_stay?: any;
  what_to_wear?: any;
  transportation?: any;
  insider_tips?: any;
  nightlife?: string;
  subheading_image?: string;
  itinerary?: string[];
  where_to_eat?: string;
}

const LocationPage = () => {
  const {country, city} = useParams<{country: string; city: string}>();
  const [location, setLocation] = useState<Location>(null);
  const assetBasePath = useContext(AssetsBasePathContext);

  console.log('log :: location ::', location);

  useEffect(() => {
    const fetchLocation = async () => {
      if (country && city) {
        const location: Location = locationsData.locations.find(
          (location: Location) => location.country.toLowerCase() === country.toLowerCase() && location.city.toLowerCase() === city.toLowerCase(),
        );

        setLocation(location);
      }
    };

    log({level: 'info', message: 'User landed on location page', data: {country, city}});

    fetchLocation();
  }, [country, city]);

  if (!location) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      {location.images?.heading_image && (
        <div className="city-heading-container">
          <img
            className="heading-image"
            src={`${assetBasePath}/assets/${city}/${location.images.heading_image}`}
            alt={location.title || "Heading"}
          />
          {location.title && <h1 className="city-title">{location.title}</h1>}
        </div>
      )}
  
      {location.summary && <p>{parse(location.summary)}</p>}
  
      {location.images?.subheading_image && (
        <img
          className="city-image"
          src={`${assetBasePath}/assets/${city}/${location.images.subheading_image}`}
          alt="Subheading"
        />
      )}
  
      {location.description && <p>{location.description}</p>}
  
      {location.where_to_stay && (
        <>
          <h2>Where we stayed</h2>
          <p>{location.where_to_stay}</p>
        </>
      )}
  
      {location.subheading_image && (
        <img
          className="city-image"
          src={`${assetBasePath}/assets/${city}/${location.subheading_image}`}
          alt="Subheading"
        />
      )}
  
      {location.itinerary?.length > 0 && (
        <>
          <h2>Itinerary</h2>
          <ul>
            {location.itinerary.map((it, index) => (
              <li key={index}>{it}</li>
            ))}
          </ul>
        </>
      )}
  
      {location.where_to_eat && (
        <>
          <h2>Where to eat</h2>
          <p>{location.where_to_eat}</p>
        </>
      )}
  
      {location.what_to_wear && (
        <>
          <h2>What to wear</h2>
          <p>{location.what_to_wear}</p>
        </>
      )}
  
      {location.transportation && (
        <>
          <h2>Transportation</h2>
          <p>{location.transportation}</p>
        </>
      )}
  
      {location.insider_tips && (
        <>
          <h2>Insider tips</h2>
          <p>{location.insider_tips}</p>
        </>
      )}
  
      {location.nightlife && (
        <>
          <h2>Nightlife</h2>
          <p>{location.nightlife}</p>
        </>
      )}
    </div>
  );
  
};

export default LocationPage;
