export default {
  locations: [
    {
      id: "523295ce-3140-48cb-bd67-b968af6ce923",
      title: "Rome",
      city: "Rome",
      country: "Italy",
      summary: `
        <h1>Roman Revelations: Food, History, and Late-Night Fun</h1>
        <h2>Italian Reds = Incrediblé</h2>
        <p>
          I'd heard plenty about Italy's famed reds, but Sangiovese took top billing for me. Imagine spicy notes with a smoky aftertaste, swirling around your palate as you lounge at a quaint trattoria—pure bliss. This wine perfectly complements any Roma-inspired pasta dish, which I'm now determined to recreate at home. Cacio e pepe, carbonara, amatriciana... you name it, I'm going for it!
        </p>
        <h2>Ordering Coffee: Macchiato, Per Favore</h2>
        <p>
          When you're ready for a break from wine (rare, but it happens), Rome's coffee culture is there to boost your caffeine levels. Don't be intimidated by the busy baristas—just order a macchiato. “Grazie!” and “Prego!” will be your new best friends. But do remember that espresso bars here don't play around; you sip, you stand, you go. Coffee is serious business.
        </p>
        <h2>Transport Trials: The Rome Bus Mystery</h2>
        <p>
          Public transport in Rome is an adventure of its own. The buses seem to run on their own schedule—if they decide to run at all…and did anyone actually pay for bus fare? We kept hearing “prego” on the buses (which means please). “Prego” for scooting past people on a crowded bus to get off. “Prego” to walk past the tap to pay post on the bus..but If you're planning to rely on them, build in extra time or stay flexible. We stayed in the Vatican City area and finally discovered on the second day that we were walking distance (<1 mile) from all of the sightseeing spots.
        </p>
        <p>
          The definition of personal space across different cultures, even down to different people can vary. However, there is no such thing as personal space on the Rome public transportation system. I have hyperaware of the space around us because my mom had been warning me of pickpockets prior to leaving. I was also already was on high alert because I had also read a few reddit threads on pickpocketing. 
        </p>

        <h2>Late Nights & Nightlife</h2>
        <p>
          One thing I quickly noticed: once the clock strikes 11:30 p.m., nearly everyone out and about is 16+ (or at least that's how it felt). The city hums with a youthful, vibrant energy, especially in the Trastevere and Monti districts. If you're looking for that late-night cocktail or a slice of pizza al taglio (pizza by the slice), you won't have to go far. Questioned our 
        </p>

        <h2>Historical Marvels at Every Turn</h2>
        <h3>St. Peter's Basilica by Night</h3>
        <p>
          Nothing compares to strolling through Vatican City as the moonlight hits St. Peter's Basilica. If you can plan an evening trip, do it. The way the marble and statues are illuminated after dark is positively ethereal. Plus, the area around the Vatican has some of the best food in town, from hearty pasta to fresh pastries. It seems the closer you get to these sacred sites, the better the flavors.
        </p>

        <h3>Who the f*ck is Victor Emmanuel II?</h3>
        <p>
          You'll see his name everywhere, most prominently at the Vittoriano monument near Piazza Venezia. He was the first king of a united Italy. The monument itself is a massive, brilliant white, wedding-cake-looking structure—impossible to miss. Despite the confusion, it's worth a visit for the panoramic views (and the chance to say you've met the mysterious Victor Emmanuel II, at least in spirit).
        </p>

        <h3>Duomos and Little Cars</h3>
        <p>
          Rome's cityscape is sprinkled with duomos (church domes) and the narrowest, cutest cars you've ever seen. Strolling from the Pantheon to Piazza Navona, you'll pass history at every turn and possibly a smart car parked in an impossible gap. Every piazza has a story, and every building seems older than your entire country. It's a living museum—just with more honking and better gelato!
        </p>

        <h3>Fashion & Packing Tips</h3>
        <p>
          Leather goods stores are everywhere, a testament to Italian craftsmanship. Shoes, bags, jackets—you'll be spoiled for choice. And don't forget to pack a puffer jacket (“Pack a puffer lol!”) because Roman weather can surprise you. Even on warm days, evenings might get a bit brisk. You'll be thankful you brought that extra layer when the temperature drops while you sip wine on an outdoor patio.
        </p>

        <h3>Inspired to Cook (and Sip) Back Home</h3>
        <p>
          Beyond the sights and sounds, Rome left me with a craving to recreate its flavors. The combination of simple, fresh ingredients like tomatoes, pecorino cheese, and olive oil is downright addictive. I'm determined to master the art of a Roman pasta dish—especially all'amatriciana—while sipping a big, bold glass of Sangiovese. Because if there's one takeaway, it's that life is just better with a dash of Rome on your plate and in your glass.
        </p>

        <h2>Final Thoughts</h2>
        <p>
          Rome is a whirlwind of tight embraces, ancient stone, and decadent eats. There's a reason people say “When in Rome…”—you truly do have to let go of your usual habits and dive into the city's rhythm. Eat the pasta, sip the wine, forgo personal space, and marvel at the centuries-old wonders that pop up around every corner.
        </p>
        <p>
          So, next time you're planning an Italian escape, don't forget: “Grazie, Prego, and Cheek = bueno.” Embrace the Roman way—no matter how unpredictable the buses are—and soak up the city's never-ending feast of history and flavor.
        </p>
        <p>
          Because in the end, Rome isn't just a destination; it's an appetite for life, adventure, and—most importantly—really good pasta.
        </p>

        <h4>Grazie for reading, and arrivederci!</h4>
      `,
      "description": "",
      "heading_image": "",
      "subheading_image": "",
      "where_to_stay": "",
      "itinerary": [],
      "where_to_eat": "",
      "what_to_wear": "",
      "transportation": "",
      "insider_tips": "",
      "nightlife": "",
      "icon": "sunny-icon",
      "highlights": [
        "History and Late-Night Fun",
        "Incredible food",
        "leather goods stores",
      ],
      "images": {
        thumbnail: "st_peters_basilica.webp",
        heading_image: "emmanual_building.webp",
        subheading_image: "st_peters_basilica.webp",
        emmanual_building: "emmanual_building.webp",
        st_peters_basilica: "st_peters_basilica.webp",
        trevi: "trevi.webp",
      }
    },
    {
      "id": "d3a2f4b6-c2e9-4977-bb7f-9e4fce6b93a8",
      "title": "Lisbon",
      "city": "Lisbon",
      "country": "Portugal",
      "summary": "Lisbon, with its mild climate, affordable living, and vibrant tech scene, is an emerging destination for remote workers. The city offers a mix of urban sophistication and coastal relaxation, with numerous coworking spaces and a growing digital nomad community.",
      "description": "Lisbon, Portugal, is becoming an increasingly popular destination for remote workers and digital nomads. The city's mild Mediterranean climate, affordable cost of living, and vibrant cultural scene make it an attractive place to live and work. Lisbon is home to a thriving tech scene, with a growing number of startups and coworking spaces designed to foster creativity and collaboration. The city's efficient public transportation system and proximity to stunning beaches offer the perfect work-life balance. Lisbon's welcoming atmosphere and growing digital nomad community ensure that remote workers quickly feel at home. Whether you're enjoying a pastel de nata in a charming cafe or catching a sunset over the Atlantic, Lisbon offers a unique blend of urban sophistication and coastal relaxation. The city's history, cuisine, and vibrant nightlife make it an exciting place to explore, while the abundance of coworking spaces makes it easy to stay productive.",
      "where_to_stay": "Lisbon has a few neighborhoods that are convenient to stay near places to go out and plenty of restaurants. Bairro Alto is a young area with lots to do",
      "itinerary": ["landed"],
      "where_to_eat": "Lisbon has a LOT of loca restaurants.",
      "what_to_wear": "",
      "transportation": "",
      "insider_tips": "",
      "nightlife": "",
      "icon": "sunny-icon",
      "highlights": [
        "Mild climate and affordable living.",
        "Thriving tech scene and numerous coworking spaces.",
        "Unique blend of urban life and coastal relaxation."
      ],
      "images": {
        thumbnail: "pink_street_edited.webp",
        "heading_image": "courtyard_edited.webp",
        "subheading_image": "pink_street_edited.webp",
        "food": "food_edited.webp",
        "pink_street_annie": "pink_street_annie_edited.webp",
        "pink_street_umbrellas": "pink_street_umbrellas_edited.webp",
        "pink_street": "pink_street_edited.webp",
        "tile_restaurant": "tile_restaurant_edited.webp",
        "timeout_market": "timeout_market_edited.webp",
        "tram": "tram_edited.webp"
      }
    },
    {
      "id": "94db17ab-ed68-4aee-917d-7a1d268163a3",
      "title": "Verbier",
      "city": "Verbier",
      "country": "Switzerland",
      "summary": "",
      "description": "",
      "heading_image": "",
      "subheading_image": "",
      "where_to_stay": "",
      "itinerary": [],
      "where_to_eat": "",
      "what_to_wear": "",
      "transportation": "",
      "insider_tips": "",
      "nightlife": "",
      "icon": "sunny-icon",
      "highlights": [
        ""
      ],
      "images": {
        "food": "food_edited.jpg",
        "pink_street_annie": "pink_street_annie_edited.jpg",
        "pink_street_umbrellas": "pink_street_umbrellas_edited.jpg",
        "pink_street": "pink_street_edited.jpg",
        "tile_restaurant": "tile_restaurant_edited.jpg",
        "timeout_market": "timeout_market_edited.jpg",
        "tram": "tram_edited.jpg"
      }
    },
    {
      "id": "f6da5dfe-ae09-4756-9fa4-29e0293238a1",
      "title": "Tulum",
      "city": "Tulum",
      "country": "Mexico",
      "summary": "Tulum has a lot to choose from from boutique and isolated hotels within Jaguar park to large all inclusives like Secrets. ",
      "description": "There were surprisingly no chihuahas, but there were so many chiuhuaha references like on t-shirts in the shops.",
      "icon": "sunny-icon",
      "highlights": [
        "Fresh fruit",
        "Stock up on over the counter drugs not available in the U.S.",
        "The people and their mannerisms. People are so nice compared to European countries and it's clear the people have a strong work ethic",
        "While hot, the restuarants and hotels are cooled by fans and it's consistently comfortable",
        "looser rules than the U.S."
      ]
    },
    
    {
      "id": "f5c8b1e9-4b37-4b14-9533-1c6a80b273d1",
      "title": "Chiang Mai",
      "city": "Chiang Mai",
      "country": "Thailand",
      "summary": "Chiang Mai's affordable living, strong expat community, and laid-back lifestyle make it a top choice for remote workers. With a low cost of living, plenty of coworking spaces, and beautiful surroundings, it's an ideal balance of work and life.",
      "description": "Chiang Mai, located in northern Thailand, is known for its affordable cost of living, strong digital nomad community, and relaxed atmosphere, making it a top destination for remote workers. The city's coworking spaces cater to a diverse crowd of freelancers, entrepreneurs, and creatives, offering opportunities for both productivity and networking. Chiang Mai's slower pace of life, combined with its proximity to nature, provides an excellent work-life balance, with opportunities for outdoor adventures like trekking and temple visits. The city's cuisine is another highlight, with street food markets and local restaurants serving affordable and delicious meals. Chiang Mai's appeal is also boosted by its reliable digital infrastructure, friendly locals, and a community that embraces remote work.",
      "icon": "sunny-icon",
      "highlights": [
        "Affordable living and strong expat community.",
        "Numerous coworking spaces catering to digital nomads.",
        "Relaxed lifestyle with access to nature and outdoor activities."
      ]
    },
    {
      "id": "e8c6a4d7-9fbc-4978-8b52-f7b5f836c9a3",
      "title": "Ho Chi Minh City",
      "city": "Ho Chi Minh City",
      "country": "Vietnam",
      "summary": "Ho Chi Minh City offers affordable living, a growing digital nomad community, and an exciting blend of modernity and tradition. It's a dynamic place for remote workers seeking cultural immersion and productivity.",
      "description": "Ho Chi Minh City, also known as Saigon, is one of Southeast Asia's most vibrant and affordable cities for remote workers. With its mix of traditional Vietnamese culture and modern infrastructure, it attracts digital nomads and entrepreneurs from around the world. The city's low cost of living, reliable internet, and a growing number of coworking spaces make it a great place for remote work. Ho Chi Minh City offers a lively street food scene, bustling markets, and numerous historical sites, ensuring there's plenty to explore after a day of work. The city's digital nomad community is growing rapidly, making it easy to network and connect with like-minded individuals. Whether you're working from a modern coworking space or a cozy cafe, Ho Chi Minh City provides a dynamic backdrop for remote work.",
      "icon": "sunny-icon",
      "highlights": [
        "Affordable living and reliable internet.",
        "Growing digital nomad community and coworking spaces.",
        "Exciting blend of modernity and tradition."
      ]
    },
    {
      "id": "a9f3c2d6-7b5e-4f18-97f5-2d5e5b1c3e4a",
      "title": "Cape Town",
      "city": "Cape Town",
      "country": "South Africa",
      "summary": "Cape Town's stunning landscapes, thriving startup scene, and diverse culture make it an attractive destination for remote workers. With coworking spaces offering breathtaking views, it's a place where work meets adventure.",
      "description": "Cape Town, South Africa, is a remote work destination that combines natural beauty with a burgeoning tech and startup scene. The city's coworking spaces offer spectacular views of Table Mountain or the ocean, creating an inspiring environment for productivity. Cape Town's diverse neighborhoods, from the trendy streets of Woodstock to the laid-back atmosphere of Camps Bay, provide a variety of living options. The city also has an affordable cost of living, especially compared to other global cities with similar amenities. With its emphasis on outdoor activities, from hiking to surfing, Cape Town offers remote workers the perfect balance between work and play. The city's digital infrastructure is solid, and its vibrant culture ensures there's always something exciting happening, whether it's a music festival, food market, or a visit to a nearby winery.",
      "icon": "sunny-icon",
      "highlights": [
        "Stunning landscapes and outdoor activities.",
        "Thriving startup scene and diverse coworking spaces.",
        "Affordable living with a vibrant culture."
      ]
    },
    {
      "id": "b2c5e7d9-1e3f-4b16-9f5a-3e8f7b2d1c3f",
      "title": "Tokyo",
      "city": "Tokyo",
      "country": "Japan",
      "summary": "Tokyo's cutting-edge technology, efficient infrastructure, and unique blend of tradition and modernity make it a compelling destination for remote workers. The city's high-tech coworking spaces and dynamic atmosphere provide endless inspiration.",
      "description": "Tokyo, Japan, is a dream destination for remote workers seeking a mix of cutting-edge technology, cultural experiences, and unparalleled efficiency. The city's coworking spaces are some of the most advanced in the world, offering everything from private offices to collaborative hubs, all equipped with high-speed internet and modern amenities. Tokyo's public transportation is world-renowned, making it easy to navigate the city's diverse neighborhoods, from the neon-lit streets of Shibuya to the peaceful temples of Asakusa. Despite its fast-paced nature, Tokyo also offers tranquil parks and traditional tea houses for moments of relaxation. The cost of living can be high, but the city's exceptional quality of life, safety, and cultural richness make it a worthwhile investment for remote workers. Whether you're working from a skyscraper with views of Mount Fuji or exploring the latest tech innovations, Tokyo offers a unique and dynamic environment for remote work.",
      "icon": "sunny-icon",
      "highlights": [
        "Cutting-edge technology and advanced coworking spaces.",
        "Efficient public transportation and infrastructure.",
        "Unique blend of tradition and modernity."
      ]
    },
    {
      "id": "d4f7a2e3-1b3f-4bc1-8a59-2f79c8d3f6a7",
      "title": "Mexico City",
      "city": "Mexico City",
      "country": "Mexico",
      "summary": "Mexico City's vibrant culture, affordable living, and thriving digital nomad community make it an exciting destination for remote workers. The city's creative scene and many coworking spaces offer a dynamic work environment.",
      "description": "Mexico City, the bustling capital of Mexico, is a top destination for remote workers due to its vibrant cultural scene, affordable cost of living, and growing digital nomad community. The city's numerous coworking spaces, cafes, and art galleries provide a stimulating environment for productivity and creativity. Mexico City's neighborhoods, such as Condesa and Roma, are particularly popular with remote workers, offering a mix of trendy cafes, coworking spaces, and green parks. The city's extensive public transportation system makes it easy to explore, while its rich history, museums, and culinary scene ensure there's always something to do outside of work. Whether you're sipping coffee in a hip cafe or exploring ancient Aztec ruins, Mexico City offers a dynamic and inspiring environment for remote work.",
      "icon": "sunny-icon",
      "highlights": [
        "Vibrant culture and creative scene.",
        "Affordable living with a thriving digital nomad community.",
        "Numerous coworking spaces and a dynamic work environment."
      ]
    },
    {
      "id": "5b32fe84-a3a3-4dc8-975e-cbe2a2942e4b",
      "title": "Bali",
      "city": "Bali",
      "country": "Indonesia",
      "summary": "A tropical haven for digital nomads, Bali offers stunning landscapes, affordable living, and a vibrant expat community. Its coworking spaces, cultural richness, and relaxed vibe make it perfect for blending work with exploration and personal growth.",
      "description": "Bali, Indonesia, is a top destination for remote workers seeking a tropical paradise with a strong digital nomad community. The island is renowned for its stunning landscapes, ranging from lush rice terraces to breathtaking beaches, making it a perfect backdrop for both work and relaxation. Bali's cost of living is relatively low, allowing for a comfortable lifestyle on a remote worker's budget. The island is equipped with reliable internet and a wide selection of coworking spaces, many of which are set in open-air and nature-inspired environments to boost creativity and productivity. Cafes and restaurants catering to a global palate also serve as informal workspaces. Bali's culture is deeply spiritual and welcoming, offering an enriching experience through its ceremonies, cuisine, and arts. The expat and digital nomad community is thriving, providing ample opportunities for networking and socializing. Despite its popularity, Bali maintains a laid-back vibe, ideal for those looking to combine work with a sense of adventure and personal growth.",
      "icon": "sunny-icon",
      "highlights": [
        "Tropical paradise popular among digital nomads.",
        "Affordable living with a variety of coworking spaces.",
        "Rich cultural experiences, cuisine, and arts."
      ]
    },
    {
      "id": "5fd2ac3d-ab44-4a2c-9d9b-63d9aeb7d453",
      "title": "Berlin",
      "city": "Berlin",
      "country": "Germany",
      "summary": "Berlin captivates remote workers with its dynamic culture, diverse community, and thriving tech scene. The city offers affordable living, creative coworking spaces, and a rich historical backdrop, making it an ideal locale for innovation and networking.",
      "description": "Berlin, Germany, stands out as a leading hub for remote workers and digital nomads, thanks to its vibrant culture, rich history, and innovative spirit. The city is known for its diversity, openness, and dynamic tech scene, making it a magnet for creatives and entrepreneurs from around the world. Berlin offers an excellent digital infrastructure, with high-speed internet and a plethora of coworking spaces designed to cater to a variety of needs and preferences. These spaces often double as community centers, hosting events, workshops, and meetups that foster collaboration and networking. The cost of living in Berlin is reasonable compared to other major European capitals, and the city's efficient public transportation system makes it easy to explore. Berlin's districts each offer a unique vibe, from the historic charm of Mitte to the trendy streets of Kreuzberg and Neukölln. The city's parks, museums, and bustling nightlife ensure that there's always something to do after logging off for the day. Berlin's inclusive atmosphere welcomes all, providing a stimulating environment for remote workers to thrive.",
      "icon": "sunny-icon",
      "highlights": [
        "Vibrant culture, rich history, and dynamic tech scene.",
        "Affordable cost of living and excellent digital infrastructure.",
        "Coworking spaces foster collaboration and networking."
      ]
    },
    {
      "id": "f0e75623-016a-4fcb-b216-176404838b2f",
      "title": "Medellin",
      "city": "Medellin",
      "country": "Colombia",
      "summary": "Medellin, known for its pleasant climate and transformation, is a draw for remote workers seeking affordability, safety, and innovation. The city's coworking spaces, friendly locals, and cultural vibrancy offer a unique blend of work and leisure opportunities.",
      "description": "Medellin, Colombia, has become a favored destination for remote workers from around the globe. Known for its 'Eternal Spring' climate, affordable cost of living, and vibrant culture, the city offers an ideal setting for those looking to balance work and leisure. The city boasts a robust digital infrastructure, with widespread high-speed internet and an abundance of coworking spaces catering to digital nomads. These spaces often provide not just a desk or private office, but a community, offering networking events, language exchanges, and workshops that enhance both personal and professional development. Medellin's welcoming locals and expat community ensure that newcomers quickly feel at home. Safety has improved significantly, and the city's public transportation system makes it easy to explore the lush landscapes and rich history of the region. From modern apartments in El Poblado to more traditional neighborhoods like Laureles, remote workers have a variety of living options to choose from. The city's emphasis on innovation and entrepreneurship also means that there are plenty of opportunities for collaboration and growth. Whether you're sipping coffee in one of the city's numerous cafes, hiking in the nearby Andes, or enjoying the lively nightlife, Medellin offers an unparalleled experience for remote workers.",
      "icon": "sunny-icon",
      "highlights": [
        "'Eternal Spring' climate, affordable living, and vibrant culture.",
        "Robust digital infrastructure with numerous coworking spaces.",
        "Welcoming locals and a focus on innovation and entrepreneurship."
      ]
    },
    {
      "id": "a7b8c9d6-e5f1-4f2b-9a8c-7d6e5f4b9a8c",
      "title": "Rio de Janeiro",
      "city": "Rio de Janeiro",
      "country": "Brazil",
      "summary": "Rio de Janeiro, with its stunning landscapes and vibrant culture, is a fantastic destination for remote workers. The city offers a mix of urban life and natural beauty, with a thriving digital community and numerous coworking spaces.",
      "description": "Rio de Janeiro, Brazil, is a dream destination for many remote workers. Known for its breathtaking landscapes, including its famous beaches and the iconic Christ the Redeemer statue, Rio offers a unique blend of natural beauty and urban life. The city is home to a thriving digital community, with numerous coworking spaces that cater to remote workers and digital nomads. These spaces often host events and workshops, providing opportunities for networking and professional development. The cost of living in Rio is relatively affordable, and the city's diverse neighborhoods offer a range of living options. From the bustling streets of Copacabana to the bohemian charm of Santa Teresa, there's a neighborhood to suit every lifestyle. The city's culture is vibrant and welcoming, with a rich history and a love for music and dance that is infectious. Whether you're working from a beachside cafe, exploring the city's historic sites, or enjoying the lively nightlife, Rio de Janeiro offers an enriching and exciting experience for remote workers.",
      "icon": "sunny-icon",
      "highlights": [
        "Stunning landscapes, beaches, and iconic Christ the Redeemer statue.",
        "Thriving digital community with numerous coworking spaces.",
        "Vibrant culture with a rich history and love for music and dance."
      ]
    },
    {
      "id": "b1c2d3e4-f5a6-4789-b1c2-d3e4f5a64789",
      "title": "Aruba",
      "city": "Oranjestad",
      "country": "Aruba",
      "summary": "Aruba, with its beautiful beaches and sunny weather, is a great place for remote workers. The island offers a relaxed lifestyle, with a friendly local community and several coworking spaces.",
      "description": "Aruba, a small island in the Caribbean, is a wonderful destination for remote workers. Known for its stunning beaches and sunny weather, Aruba offers a relaxed and comfortable lifestyle. The island has a friendly local community and a growing number of coworking spaces, making it a suitable place for remote work. The cost of living in Aruba is relatively high compared to other Caribbean islands, but the quality of life and safety it offers make it worth it. Whether you're working from a beachside cafe or your apartment, Aruba provides a peaceful and inspiring environment for remote workers.",
      "icon": "sunny-icon",
      "highlights": [
        "Known for stunning beaches and sunny weather.",
        "Relaxed and comfortable lifestyle.",
        "Friendly local community and growing number of coworking spaces."
      ]
    },
    {
      "id": "e6f7a8b9-c1d2-e3f4-a5b6-c7d8e9f0a1b2",
      "title": "Santo Domingo",
      "city": "Santo Domingo",
      "country": "Dominican Republic",
      "summary": "Santo Domingo, with its rich history and vibrant culture, is an exciting place for remote workers. The city offers a mix of urban life and Caribbean charm, with a growing digital community and numerous coworking spaces.",
      "description": "Santo Domingo, the capital of the Dominican Republic, is a city full of history and culture. It's an exciting place for remote workers, offering a mix of urban life and Caribbean charm. The city has a growing digital community, with numerous coworking spaces that cater to remote workers and digital nomads. These spaces often host events and workshops, providing opportunities for networking and professional development. The cost of living in Santo Domingo is relatively affordable, and the city's diverse neighborhoods offer a range of living options. From the historic charm of the Zona Colonial to the modern amenities of Piantini, there's a neighborhood to suit every lifestyle. The city's culture is vibrant and welcoming, with a rich history and a love for music and dance that is infectious. Whether you're working from a cafe in the city, exploring the city's historic sites, or enjoying the lively nightlife, Santo Domingo offers an enriching and exciting experience for remote workers.",
      "icon": "sunny-icon",
      "highlights": [
        "Mix of urban life and Caribbean charm.",
        "Growing digital community with numerous coworking spaces.",
        "Affordable living and rich history with vibrant cultural scene."
      ]
    },
    {
      "id": "f1a2b3c4-d5e6-f7a8-b9c0-d1e2f3a4b5c6",
      "title": "Toronto",
      "city": "Toronto",
      "country": "Canada",
      "summary": "Toronto, with its diverse culture and thriving tech scene, is a fantastic destination for remote workers. The city offers a mix of urban life and natural beauty, with a thriving digital community and numerous coworking spaces.",
      "description": "Toronto, Canada, is a dream destination for many remote workers. Known for its diverse culture, thriving tech scene, and beautiful landscapes, Toronto offers a unique blend of urban life and natural beauty. The city is home to a thriving digital community, with numerous coworking spaces that cater to remote workers and digital nomads. These spaces often host events and workshops, providing opportunities for networking and professional development. The cost of living in Toronto is relatively high, but the city's diverse neighborhoods offer a range of living options. From the bustling streets of Downtown to the peaceful parks of High Park, there's a neighborhood to suit every lifestyle. The city's culture is vibrant and welcoming, with a rich history and a love for music and art that is infectious. Whether you're working from a cafe in the city, exploring the city's historic sites, or enjoying the lively nightlife, Toronto offers an enriching and exciting experience for remote workers.",
      "icon": "sunny-icon",
      "highlights": [
        "Diverse culture, thriving tech scene, and beautiful landscapes.",
        "Numerous coworking spaces and thriving digital community.",
        "High cost of living but diverse neighborhoods with vibrant culture."
      ]
    },
    {
      "id": "g7h8i9j0-k1l2-m3n4-o5p6-q7r8s9t0u1v2",
      "title": "Barcelona",
      "city": "Barcelona",
      "country": "Spain",
      "summary": "Barcelona, known for its rich history, stunning architecture, and vibrant cultural scene, is an ideal destination for remote workers. The city offers a perfect blend of work and leisure with numerous coworking spaces and a strong digital community.",
      "description": "Barcelona, Spain, is a top choice for remote workers and digital nomads. The city is famous for its unique architecture by Antoni Gaudí, beautiful beaches, and a lively cultural scene. Barcelona's diverse neighborhoods, from the historic Gothic Quarter to the modern Eixample district, offer something for everyone. The city boasts numerous coworking spaces that cater to remote professionals, providing a supportive environment for productivity and networking. Despite a relatively high cost of living, Barcelona's quality of life is exceptional, with excellent public transportation, delicious cuisine, and a welcoming atmosphere. The city's Mediterranean climate ensures plenty of sunshine year-round, making it an attractive place to live and work. Whether you're exploring the city's historic sites, enjoying a beach day, or working from a chic cafe, Barcelona provides a dynamic and inspiring environment for remote workers.",
      "icon": "sunny-icon",
      "highlights": [
        "Rich history, stunning architecture, and vibrant cultural scene.",
        "Numerous coworking spaces and a strong digital community.",
        "High cost of living but exceptional quality of life and Mediterranean climate."
      ]
    },
    {
      "id": "w2x3y4z5-a6b7-c8d9-e0f1-g2h3i4j5k6l7",
      "title": "Madrid",
      "city": "Madrid",
      "country": "Spain",
      "summary": "Madrid, with its rich cultural heritage, lively atmosphere, and modern amenities, is a fantastic destination for remote workers. The city offers a perfect blend of historical charm and contemporary conveniences, with numerous coworking spaces and a vibrant digital community.",
      "description": "Madrid, the capital of Spain, is an excellent destination for remote workers and digital nomads. Known for its rich cultural heritage, beautiful architecture, and bustling social scene, Madrid offers a unique blend of historical charm and modern conveniences. The city's various neighborhoods, such as the historic center, the trendy Malasaña, and the chic Salamanca district, provide diverse living options to suit different lifestyles. Madrid boasts numerous coworking spaces that cater to remote professionals, offering a supportive environment for productivity and networking. While the cost of living is relatively high, the quality of life in Madrid is exceptional, with efficient public transportation, delicious cuisine, and a warm and welcoming atmosphere. The city's climate is characterized by hot summers and mild winters, making it an attractive place to live and work year-round. Whether you're exploring Madrid's historic landmarks, enjoying its vibrant nightlife, or working from a stylish cafe, the city provides a dynamic and inspiring environment for remote workers.",
      "icon": "sunny-icon",
      "highlights": [
        "Rich cultural heritage, beautiful architecture, and bustling social scene.",
        "Numerous coworking spaces and a vibrant digital community.",
        "Relatively high cost of living but exceptional quality of life and efficient public transportation."
      ]
    }
  ]
}
